@use '@angular/material' as mat;

// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-typography-config());`
@include mat.all-component-typographies();
@include mat.core();
@import './palette';
$light-primary: mat.get-color-from-palette($rina-lightgray, 500, 100);
$light-accent: mat.get-color-from-palette($rina-lightgray, 500, 100);
$light-background: mat.get-color-from-palette($rina-lightgray, 700, 100);
$light-warn: mat.get-color-from-palette($rina-warn, 400, 100);
.user-menu {
  background-color: mat.get-color-from-palette($rina-grayweb, 800);
}
rina-sidenav-item {
  .active {
    border-left: 3px solid rgba(0, 0, 0, 0.87);
  }
}

// theming generic components

rina-entity-assigner,
rina-role-edit {
  .selected-item {
    background-color: mat.get-color-from-palette($rina-grayweb, 800);
  }
  /* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version.*/
  .mat-mdc-chip.mat-mdc-standard-chip.mat-chip-selected.mat-primary {
    background: #aaa;
    color: mat.get-color-from-palette($rina-grayweb, 800);
  }

  .panel-style {
    background-color: mat.get-color-from-palette($rina-grayweb, 800);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version.*/
.mat-mdc-checkbox:not(.mat-checkbox-disabled).mat-accent .mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: mat.get-color-from-palette($rina-blue, 500);
}
